$app_bgr_light: #f1f2f7;

$deeporange_50: #FBE9E7;
$deeporange_300: #FF8A65;
$deeporange_700: #E64A19;
$deeporange_900: #BF360C;

$gray_50: #FAFAFA;
$gray_300: #E0E0E0;
$gray_700: #616161;
$gray_900: #212121;

$menu_1_color: #6e32fb;
$menu_2_color: #fc8e65;
$menu_3_color: #fdca41;

$title_size: 2em;

@mixin height-100vh {
    height: 100vh;
}

html {
    @include height-100vh();
}

body {
    @include height-100vh();
    background-color: $app_bgr_light !important;
}

#root,
.App,
.content {
    @include height-100vh();
}

.menu {
    background-color: white;
}

.rn-title-h {
    height: $title_size;
}

.rn-title-fs {
    font-size: $title_size;
}

// ==== Menu list
.rn-menu1-icon {
    background-color: $menu_1_color;
}
.rn-menu2-icon {
    background-color: $menu_2_color;
}
.rn-menunx-icon {
    border-color: $gray_700;
    border-style: dashed;
    border-width: 2px;
}

@import "../node_modules/bootstrap/scss/bootstrap.scss"